function add_js_input(form_id){
    $('<input>').attr({
                type: 'hidden',
                id: 'captcha',
                name: 'captcha',
                value: 'honey'}).appendTo(form_id);
}
$(document).ready(function(){

    //materialDocumentReady();
    //if($("#contactUsMap").length) {
    //    materialKitDemo.initContactUsMap();
    //}
    $('.parallax-window').parallax();

});
$(window).on("load", function (e) {

    $("table").addClass('table table-bordered table-hover');
    if(window.validate_contact_form){
		validate_contact_form();
		add_js_input('#contact_form');
	}

})
//$(window).load(function() {

//});
$(document).on('click', '[data-toggle="lightbox"]', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox();
});